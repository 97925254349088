import React from "react";
import CreatePersonsForm from "../components/CreatePersonsForm";
import {useAuth} from "../components/AuthProvider";
import PersonsContainer from "../components/PersonsContainer";

import Button from "@mui/joy/Button";
import Add from "@mui/icons-material/Add";

function Persons() {

    const {checkToken, token} = useAuth();
    const [open, setOpen] = React.useState(false);
    const [personID, setPersonID] = React.useState(null);

    const Refresh =  () => {
        if(!token || !checkToken(token)){
            return;
        }


        //reload the page
        window.location.reload();
    };


    return (
        <div>
            <h1 className={"pageHeader"}>People</h1>

            <PersonsContainer setOpen={setOpen} setPersonID={setPersonID}/>
            {open ? <CreatePersonsForm
                refresh={
                    //refresh the page
                    () => {
                        Refresh();
                    }
                }
                setOpen={setOpen}
                person_id={personID}
            /> :
            null}
            <Button
                variant="soft"
                color="warning"
                startDecorator={<Add/>}
                style={{marginTop: "20px"}}
                onClick={() => {

                    setPersonID(null);
                    setOpen(true);
                }}

            >
                New Person ✨
            </Button>


        </div>
    );
}




export default Persons;