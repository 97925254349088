import React from "react";
import {Sidebar} from "./Sidebar";

import "../styles/Dashboard.css"
import {useLocation} from "react-router-dom";

function Content({children}){
    return (
        <div className="content">
            {children}
        </div>
    );
}

export default function Dashboard({children}){

    return (
        <div className="dashboard">
            <Sidebar className="sidebar"/>
            <Content children={children}/>
        </div>
    );
}

