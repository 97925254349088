// https://dev.to/sanjayttg/jwt-authentication-in-react-with-react-router-1d03
import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {decodeCreditional} from "../utils/googleApi";


const AuthContext = createContext(
);

const AuthProvider = ({ children }) => {
  // State to hold the authentication token
  const [token, setToken_] = useState(localStorage.getItem("token"));
  const [username, setUsername_] = useState(null);
  const [userid, setUserid_] = useState(null);
  const [profile_pic, setProfilePic_] = useState(null);

  //function to check if token hasn't been tampered with
  const checkToken = (token) => {
    if (token) {
      try {
        return decodeCreditional(token);
      } catch (error) {
        console.log(error);
        return null;
      }
    }

    return null;
  };
  
  // Function to set the authentication token
  const setToken = (newToken) => {
    setToken_(newToken);

    if (newToken) {
      let decoded = decodeCreditional(newToken);
      setUsername_(decoded.name);
      setUserid_(decoded.sub);
      setProfilePic_(decoded.picture);
    } else {
      setUsername_(null);
      setUserid_(null);
      setProfilePic_(null);
    }
  };

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      try {
        let decoded = decodeCreditional(token);
        setUsername_(decoded.name);
        setUserid_(decoded.sub);
        setProfilePic_(decoded.picture);
      } catch (error) {
        localStorage.removeItem('token');
        setUsername_(null);
        setUserid_(null);
        setProfilePic_(null);
      }

    } else {
      localStorage.removeItem('token');
      setUsername_(null);
      setUserid_(null);
      setProfilePic_(null);
    }
  }, [token]);

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      username,
      userid,
      checkToken,
      profile_pic
    }),
    [token, username, userid, profile_pic]
  );

  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
