import React from "react";
import {Menubar} from "../components/Menubar";
import {useNavigate} from "react-router-dom";

import "../styles/Home.css";
import LoginButton from "../components/LoginButton";

const stats = [{id: 1, name: 'Events reminded every 24 hours', value: '200.000'}, {
    id: 2,
    name: 'Events created annually',
    value: '1.200.000'
}, {id: 3, name: 'New users annually', value: '46,000'},]
export default function LandingPage() {
    const navigate = useNavigate();
    return (<
    div className={"backgroundLandingPage"}
    >

            <Menubar/>

            <div
                className="relative isolate overflow-hidden mt-5 px-6 pt-16 sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">

                <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
                    <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Boost your
                        productivity. 📈<br/>Never forget a Birthday 🎂</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-300">
                        Never forget a birthday or an important date again. Remindify will remind you of all the
                        important events in your life.
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                        <LoginButton/>
                        <a href="#" className="text-sm font-semibold leading-6 text-white">Learn more <span
                            aria-hidden="true">→</span></a>
                    </div>
                </div>
                <div className="relative mt-16 h-80 lg:mt-8">
                    <img
                        className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10 sm:w-[42rem] lg:w-[57rem] lg:-left-16 lg:top-16"
                        src="https://i.ibb.co/fp28pW3/image.png"
                        alt="App screenshot" width="1860" height="885"/>
                </div>
            </div>
            <span className="relative flex justify-center">
  <div
      className="absolute inset-x-0 top-1/2 h-px -translate-y-1/2 bg-transparent bg-gradient-to-r from-transparent via-gray-500 to-transparent opacity-75
      mt-20"
  ></div>
</span>
            <div className="py-24 sm:py-32 text-white">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
                        {stats.map((stat) => (<div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
                                <dt className="text-base leading-7 ">{stat.name}</dt>
                                <dd className="order-first text-3xl font-semibold tracking-tight sm:text-5xl">
                                    {stat.value}
                                </dd>
                            </div>))}
                    </dl>
                </div>
            </div>



        </div>);
}

