import React from "react";
import {Routes, Route, BrowserRouter} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ProtectedRoute } from "./components/ProtectedRoute";
import AuthProvider from "./components/AuthProvider";
import LandingPage from "./pages/LandingPage";

import Settings from "./pages/Settings";
import Persons from "./pages/Persons";
import Reminders from "./pages/Reminders";
import Person from "./pages/Person";
import EventView from "./pages/EventView";

import "./App.css";
export default function App() {

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/" element={<ProtectedRoute />}>

            <Route path="/settings" element={<Dashboard children={<Settings/>}/>} />
            <Route path="/persons" element={<Dashboard children={<Persons/>} />} />
            <Route path="/reminders" element={<Dashboard children={<Reminders/>}/>} />
            <Route path="/persons/:person_id" element={<Dashboard children={<Person/>}/>} />
            <Route path="/event/:event_id" element={<Dashboard children={<EventView/>}/>} />
          </Route>



        </Routes>

      </BrowserRouter>
      <ToastContainer
        position={"top-left"}
        theme={"colored"}
        autoClose={500}

      />
    </AuthProvider>
  );
}