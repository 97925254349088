import React, {useEffect, useState} from "react";
import {useAuth} from "../components/AuthProvider";
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {get} from "../utils/useApi";
import EventsTable from "../components/EventsTable";
import dayjs from "dayjs";
import CreateEventForm from "../components/CreateEventForm";
import Button from "@mui/joy/Button";
import Add from "@mui/icons-material/Add";

const rows = [];

function Person() {
    const navigate = useNavigate();

    const [events, setEvents] = useState(rows);
    const {checkToken, token} = useAuth();
    const [person, setPerson] = useState({});

    const [mode, setMode] = React.useState("birthday");
    const [open, setOpen] = React.useState(false);

    const [event_id, setEvent_id] = React.useState(null); //for editing event

    const location = useLocation();
    const person_id = location.pathname.split("/")[2];

    const Refresh  = ()  => {
        if(!token || !checkToken(token)){
            navigate("/persons");
            toast.error("Failed to load this person's details.");
        }
        get(token, `/persons/${person_id}`, null).then(response => {
            setPerson(response);
        }).catch(error => {
            console.log(error);
            toast.error("Error fetching person details")
            navigate("/persons")
        });

        get(token, "/events/person/" + person_id, null).then(response => {

            if(response.length === 0){
                toast.info("This person has no events.")
            }else{
                toast.success("Events fetched.")
            }
            setEvents(
                response.map((event, index) => {
                    return {
                        id: index,
                        event_id: event.event_id,
                        person_id: event.person_id,
                        user_id: event.user_id,
                        //from day = 1, month = 1 to January 1
                        date: dayjs().month(event.month).date(event.day),
                        event_type: event.event_type === "birthday" ? "BD 🎂" : event.event_type,
                        description: event.description,
                        title: event.title,
                        receive_notif: event.receive_notif === true ? "✅" : "❌",
                        updateState: Refresh.bind(this),
                        remind_before: event.remind_before,
                        setOpen : setOpen,
                        setEvent : setEvent_id,
                    }
                })
            );
        }).catch(error => {
            console.log(error);
            toast.error("Error fetching person's events")
        });


    };

    useEffect(() => {
        Refresh();
    },[]);


    return (
        <div>
            <h1 className={"pageHeader"}>{person.name}</h1>

            <EventsTable rows={events}/>

            <Button variant="outlined" color="neutral" startDecorator={<Add/>} key="1" style={{marginTop: "20px", marginRight: "20px"}}
                    onClick={() => {
                        setMode("birthday");
                        setOpen(true)
                        setEvent_id(null);
                    }}>🎂 Add Birthday</Button>,

            <Button variant="outlined" color="neutral" startDecorator={<Add/>} key="2" style={{marginTop: "20px", marginRight: "20px"}}
                    onClick={() => {
                        setMode("");
                        setOpen(true)
                        setEvent_id(null);
                    }}>✨ Add Other Event</Button>

            {
                open ?
                    <CreateEventForm
                        person_id_={person_id}
                        mode={mode}
                        refresh={Refresh}
                        token={token}
                        setOpen={setOpen}
                        event_id={event_id}
                    /> : null
            }


        </div>
    );
}




export default Person;