import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import {useAuth} from "./AuthProvider";
import {delete_} from "../utils/useApi";
import {toast} from "react-toastify";
import "../styles/PersonsTable.css";
import DeleteIcon from '@mui/icons-material/Delete';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import PageviewIcon from '@mui/icons-material/Pageview';
import EditIcon from "@mui/icons-material/Edit";

import {decodeCreditional} from "../utils/googleApi";
import {useNavigate} from "react-router-dom";

import {format} from 'date-fns'
import CreateEventForm   from "./CreateEventForm";
import dayjs from "dayjs";

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 70,
        editable: false,
    },

    {
        field: 'actions',
        headerName: 'Actions',
        // right align the buttons
        //column on far right
        align: 'left',

        width: 120,
        renderCell: (params) => {
            return (
                <>
                    <DeleteButton personID={params.row.personID} updateState={params.row.updateState}
                    event_id={params.row.event_id}/>
                    <ViewButton event_id={params.row.event_id}/>
                    <EditButton event_id={params.row.event_id} updateState={params.row.updateState}
                                setOpen={params.row.setOpen}
                                setEvent_id={params.row.setEvent}
                    />

                </>
            );
        },
        flex: 1.5,
    },
    {
        field: 'event_id',
        headerName: 'Event ID',
        width: 150,
        editable: false,
        flex: 1,
    },
    {
        field:'person_id',
        headerName:'Person ID',
        width:150,
        editable:false,
    },
    {
        field:'event_type',
        headerName:'Event Type',
        width:80,
        editable:false,
        flex: 1,
    },
    {
        field:'title',
        headerName:'Title',
        width:150,
        editable:false,
        flex: 1,
    },
    {
        field:'date',
        headerName:'Date',
        width:150,
        editable:false,
        flex: 1.2,
        sortComparator: (v1, v2, cellParams1, cellParams2) => {
            return dayjs(cellParams1.value).isBefore(dayjs(cellParams2.value)) ? -1 : 1;
        },
        valueFormatter: (params) => {
            return dayjs(params.value).format('DD/MM/YYYY');
        },
    },
    {
        field:'remind_before',
        headerName:'Remind Before',
        width:150,
        editable:false,
        flex: 1.1,
    },
    {
        field:'receive_notif',
        headerName:'🔔',
        width:50,
        editable:false,
        flex: 0.5,
    },
    {
        field:'description',
        headerName:'Description',
        width:500,
        editable:false,
        flex: 3,
    }
];

function renderDate(date) {
    console.log(date)
    console.log("sui")
    if (!date) {
        return '';
    }
    return dayjs(date).format("MMMM D").toString()
}
function EditButton({event_id,setOpen, setEvent_id}) {

    return (
        <EditIcon
            variant="outlined"
            color="primary"
            className={"editButton"}
            style={{cursor: "pointer", marginRight: "10px"}}
            onClick={() => {
                setEvent_id(event_id);
                setOpen(true);
            }}
        >

    </EditIcon>)
}
function ViewButton({event_id}) {
    const navigate = useNavigate();
    return (
        <PageviewIcon
            variant="outlined"
            color="primary"
            className={"viewButton"}
            style={{cursor: "pointer", marginRight: "10px"}}
            onClick={() => {
                navigate("/event/" + event_id);
            }}
        >
            View
        </PageviewIcon>
    );

}
function DeleteButton({personID,updateState, event_id}) {
    const {token} = useAuth();
    let decoded = decodeCreditional(token);
    let userid = decoded.sub;



    return (
        <DeleteIcon
            variant="outlined"
            color="error"
            className={"deleteButton"}
            style={{cursor: "pointer", marginRight: "10px"}}
            onClick={() => {
                if (window.confirm("Are you sure you want to delete this event?")) {
                    delete_(token, "/events/" + event_id, null).then(response => {
                        toast.success("Event deleted.")
                        updateState();
                    }).catch(error => {
                        toast.error("Error deleting event.")
                    });
                }

            }}
        >
            Delete
        </DeleteIcon>
    );
}

export default function EventsTable({rows}) {
    let windowWidth = window.innerWidth;
    return (
        <>
        <Box sx={{ height:400, width: '100%' }}
            style={{
                minHeight: "400px",
                maxHeight: "1000px",

            }}
        >
            <DataGrid
                rows={rows}
                columns={
                windowWidth < 600 ? columns.filter(column => {
                    //remove the description and event type columns
                    return column.field !== "description" && column.field !== "remind_before"
                }) : columns
                }
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 25,
                        },
                    },
                    sorting: {
                        sortModel: [{field: 'date', sort: 'asc'}]
                    }
                }}

                style={{
                //     check if the window width is less than 600px
                //     if it is, set the width of the table to 100%

                    fontSize: windowWidth < 600 ? "10px" : "12px",
                    backgroundColor: "#f5f5f5",
                }}
                pageSizeOptions={[25]}

                columnVisibilityModel={
                    {
                        //  Hide the event id, this is the id of the event in the database
                        id: false,
                        person_id: false,
                        user_id: false,
                        event_id: false,
                    }
                }

                //remove the options to sort the columns
                disableColumnMenu={true}


            />
        </Box>


</>
    );
}