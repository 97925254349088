import React from 'react';
import ReactDOM from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {GoogleOAuthProvider} from "@react-oauth/google"

import "./index.css"
import './styles/global.css';

import App from './App';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <React.StrictMode>

        <GoogleOAuthProvider clientId="741991010133-ied9j2r03vjh3ust8fshihfna2kqtc3o.apps.googleusercontent.com">
            <LocalizationProvider dateAdapter={AdapterDayjs}>

                <App/>
            </LocalizationProvider>
        </GoogleOAuthProvider>

    </React.StrictMode>
);

