import {React, useState} from 'react';
import Button from "@mui/joy/Button";
import {ButtonGroup} from "@mui/joy";

import "../styles/PersonCard.css";

function PersonCard({person, onClick, onDelete, onEdit}){
    const [personID, setPersonID] = useState(person.person_id);

    return (
        <div style={{


        }}>

            <div className={"card"}>
                <img src={
                    "https://www.gravatar.com/avatar/"+personID+"?d=identicon"
                } alt={"person"}/>

                <h3 style={{
                    fontWeight: "bold",
                    fontSize: "20px"
                }}>{person.name}</h3>

               <h2
                   style={{
                          fontStyle: "italic",
                          fontSize: "18px"
                   }}
               >{person.relationship_type}</h2>

                <ButtonGroup

                    color="primary"
                    aria-label="contained primary button group"
                    style={{
                        margin: "0 auto"
                    }}
                >
                    <Button onClick={() => {onEdit(person)}}>Edit</Button>
                    <Button onClick={() => {onDelete(person)}}>Delete</Button>
                    <Button onClick={() => {onClick(person)}}>View</Button>
                </ButtonGroup>
            </div>
        </div>
    )
}

export default PersonCard;