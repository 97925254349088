import React, {useEffect} from "react";
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import {useAuth} from "./AuthProvider";
import {post} from "../utils/useApi";
import {toast} from "react-toastify";
import {get, put} from "../utils/useApi";
import {useNavigate} from "react-router-dom";

function CreatePersonsForm({refresh, person_id=null, setOpen}) {
    const {token, userid} = useAuth();

    const [name, setName] = React.useState("");
    const [relationship_type, setRelationship_type] = React.useState("");

    useEffect(() => {
        if(person_id){
            //edit mode
            get(token, "/persons/" + person_id, null).then(response => {
                console.log(response);
                setName(response.name);
                setRelationship_type(response.relationship_type);
                console.log("setting name and relationship type");
            }).catch(error => {
                console.log(error);
                toast.error("Error fetching person")
            });
        }
    }, []);


    return (
        <>
            <Modal open={true} onClose={() => setOpen(false)}>
                <ModalDialog>
                    <DialogTitle>{
                        person_id ? "Edit this Person 📎" : "Add a new Person ✨"
                    }</DialogTitle>
                    <DialogContent>Fill in the person's information.</DialogContent>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            setOpen(false);
                            console.log("submitting form");
                            console.log(name);
                            console.log(relationship_type);

                            if(person_id) {
                                put(token, "/persons", {
                                    name: name,
                                    relationship_type: relationship_type,
                                    emoji: "👤",
                                    user_id: userid,
                                    person_id: person_id
                                }).then(r => {
                                    console.log(r);
                                    toast.success("Person created.");
                                    refresh();

                                }).catch(e => {
                                    console.log(e);
                                    toast.error("Failed to create person.");
                                });
                            }else{
                                post(token, "/persons/", {
                                    name: name,
                                    relationship_type: relationship_type,
                                    emoji: "👤",
                                    user_id: userid
                                }).then(r => {
                                    console.log(r);
                                    toast.success("Person updated.");
                                    refresh();

                                }).catch(e => {
                                    console.log(e);
                                    toast.error("Failed to update person.");
                                });
                                }

                        }}>
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input autoFocus required placeholder={"John Doe"}
                                onChange={
                                    (event) => setName(event.target.value)
                                }
                                value={name}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Relation with this Person</FormLabel>
                                <Input autoFocus required placeholder={"Father/Cousin/Work Colleague"}
                                onChange={
                                    (event) => setRelationship_type(event.target.value)
                                }
                                value={relationship_type}

                                />
                            </FormControl>

                            <Button type="submit">Submit</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        </>
    );
}



export default CreatePersonsForm;