import {apiURL as apiURL_, baseUrl} from "../config";

class ApiError extends Error {
    constructor(message) {
        super(message);
        this.name = "ApiError";
    }
}


const get = (async (token, url, params) => {
    const apiUrl = new URL(url, apiURL_);
    if (params) Object.keys(params).forEach(key => apiUrl.searchParams.append(key, params[key]));

    const response = await fetch(
        apiUrl,
        {
            headers: new Headers({
                "Authorization": `Bearer ${token}`,
                "origin": baseUrl,
            }),
        }
    ).catch((error) => {
        console.log(error);
        throw new ApiError("Failed to fetch data from the server.");
    });


    try {
        var data = await response.json();
    } catch (error) {
        console.log(error);
        data = {};
    }


    if (response.status === 404) {
        if (data.error === "User does not exist.") {
            return data;
        }
    }
    if (!response.ok) {
        throw new ApiError(data.error);
    }

    return data;
});

const post = (async (token, url, body) => {
    const apiUrl = new URL(url, apiURL_);

    const response = await fetch(
        apiUrl,
        {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            }),
            body: JSON.stringify(body),
        }
    );

    try {
        var data = await response.json();
    } catch (error) {
        console.log(error);
        data = {};
    }

    if (!response.ok) {
        throw new ApiError(data.error);
    }

    return data;
});

const delete_ = (async (token, url) => {
    const apiUrl = new URL(url, apiURL_);
    console.log(apiUrl);
    const response = await fetch(
        apiUrl,
        {
            method: "DELETE",
            headers: new Headers({
                "Authorization": `Bearer ${token}`,
            }),
        }
    );

    try {
        var data = await response.json();
    } catch (error) {
        console.log(error);
        data = {};
    }

    if (!response.ok) {
        throw new ApiError(data.error);
    }

    return data;
});

const put = (async (token, url, body) => {
    const apiUrl = new URL(url, apiURL_);

    const response = await fetch(
        apiUrl,
        {
            method: "PUT",
            headers: new Headers({
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            }),
            body: JSON.stringify(body),
        }
    );

    try {
        var data = await response.json();
    } catch (error) {
        console.log(error);
        data = {};
    }

    if (!response.ok) {
        throw new ApiError(data.error);
    }

    return data;
});


export {get, post, delete_, put};