import React from 'react';
import {useNavigate} from "react-router-dom";
import {useAuth} from "./AuthProvider";
import "../styles/Sidebar.css";
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemButton from '@mui/joy/ListItemButton';
import {decodeCreditional} from "../utils/googleApi";
import LogoutIcon from '@mui/icons-material/Logout';


function Sidebar() {
    const navigator = useNavigate();
    const {setToken,token} = useAuth();
    let userid = decodeCreditional(token).sub;
    let username = decodeCreditional(token).name;
    return (

        <List className="sidebar"
              style={{
                  "display": "flex",
                  "flexDirection": "column",
                  "justifyContent": "flex-start",
                  "wrap": "nowrap",
              }}>
            <ListItem>
                <p onClick={() => navigator("/")} id="logo">Remindify</p>
            </ListItem>
            <ListItemButton onClick={() => navigator("/reminders")}>
                <ListItemDecorator>
                    📅
                </ListItemDecorator>
                Reminders
            </ListItemButton>
            <ListItemButton onClick={() => navigator("/persons")}>
                <ListItemDecorator>
                    👨‍👩‍👧‍👦
                </ListItemDecorator>
                Persons
            </ListItemButton>
            {/*add space*/}

            {/*check if mobile*/}
            {window.innerWidth > 600 ?
                <span style={{
                    backgroundColor: "#fdf5e9",
                    height: "100%",
                    textAlign: "center",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                emails do not actually get sent, as I did not set up a mail server.
            </span>
                : null
            }


            <ListItemButton onClick={() => navigator("/settings")
            }
                            className={"bottomItem"}
                            style={
                                {
                                    "justifySelf": "flex-end",
                                }
                            }>
                <ListItemDecorator>
                    ⚙
                </ListItemDecorator>
                Settings
            </ListItemButton>



            <ListItem
                style={
                {
                    "justifySelf": "flex-end",
                    borderTop: "1px solid grey",
                }
                }
            >
                <ListItemDecorator>
                    👤
                </ListItemDecorator>
                <div
                    style={{
                        "display": "flex",
                        "flexDirection": "row",
                       "flexWrap": "nowrap",
                        "width": "100%",
                        "alignItems": "center",
                        "justifyContent": "flex-start",
                    }}
                    >
                    <p>{username}</p>
                    <ListItemButton id={"logout"}

                        onClick={() => {
                            setToken(null);
                            navigator("/");
                        }}>
                        <ListItemDecorator>
                            <LogoutIcon/>
                        </ListItemDecorator>
                    </ListItemButton>
                </div>

            </ListItem>


        </List>
    );
}


//export the navbar
export {Sidebar}