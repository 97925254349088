import Grid from '@mui/material/Unstable_Grid2';
import {useEffect, useState} from "react";
import {delete_, get} from "../utils/useApi";
import {useAuth} from "./AuthProvider";
import {decodeCreditional} from "../utils/googleApi";
import {toast} from "react-toastify";
import PersonCard from "../components/PersonCard";
import {useNavigate} from "react-router-dom";

import "../styles/PersonsContainer.css";

function PersonsContainer({setOpen, setPersonID}) {
    const {token} = useAuth();
    let userid = decodeCreditional(token).sub;

    const [persons, setPersons] = useState([]);

    const navigate = useNavigate();

    const Refresh =  () => {

        get(token, "/persons/user/" + userid, null).then(r => {
            setPersons(r);
            toast.success("Persons loaded.")
            console.log(r)
        }).catch(e => {
            console.log(e);
            toast.error("Failed to get persons.");
        });

    }

    useEffect(() => {
        Refresh();
    }, []);

    return (

        //loading spinner
        <div className={"persons_container"}>
                <Grid container spacing={{ xs: 1, sm:2, md: 1 }} columns={{ xs: 3, sm: 9, md: 12 }}
                style={{
                    //center the grid
                    justifyContent: "center",
                    borderRadius: "10px",
                    padding: "5px",
                    margin: "0px",
                    backgroundColor: "#f5f5f5",
                    boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
                    textAlign: "center",
                    gap: "10px",
                    maxHeight: '75vh',
                    overflow: 'auto'
                }}>
                {persons.map(person => {
                    return (
                        <Grid xs={3} sm={4} md={3} key={person.person_id}>
                            <PersonCard person={person}
                                onEdit={()=>{
                                    setPersonID(person.person_id);
                                    setOpen(true);
                                    Refresh();
                                }}
                                onDelete={()=>{
                                    if (window.confirm("Are you sure you want to delete this person?")) {
                                    delete_(token, "/events/person/"+person.person_id).then(() => {
                                        toast.success("All reminders for this person deleted.");
                                    }).catch(e => {
                                        console.log(e);
                                        toast.error("Failed to delete reminders for this person.");
                                    })
                                    delete_(token, "/persons/"+person.person_id).then(r => {
                                        toast.success("Person deleted.");
                                        //     reload the page
                                        console.log(r);

                                        Refresh();
                                    }).catch(e => {
                                        console.log(e);
                                        toast.error("Failed to delete person.");
                                    });
                                }}}
                                onClick={()=>{
                                            navigate("/persons/"+person.person_id)
                                        }}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    );
}

export default PersonsContainer;