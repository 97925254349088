import React, {useEffect, useState} from "react";
import {get} from "../utils/useApi";
import {useAuth} from "../components/AuthProvider";
import EventsTable from "../components/EventsTable";
import CreateEventForm from "../components/CreateEventForm";
import dayjs from "dayjs";

import "../styles/Reminders.css";
import {decodeCreditional} from "../utils/googleApi";

const rows = [

];
function Reminders() {
    const [events, setEvents] = useState(rows);
    const {token} = useAuth();

    const [mode, setMode] = React.useState("birthday");
    const [open, setOpen] = React.useState(false);
    const [event_id, setEvent_id] = React.useState(null); //for editing event


    let userid = decodeCreditional(token).sub;
    const Refresh = () => {
        get(token, `/events/user/${userid}`, null).then(response => {
            setEvents(
                response.map((event, index) => {
                    return {
                        id: index,
                        event_id: event.event_id,
                        person_id: event.person_id,
                        user_id: event.user_id,
                        date: dayjs().month(event.month ).date(event.day),
                        event_type: event.event_type === "birthday" ? "BD 🎂" : event.event_type,
                        description: event.description,
                        title: event.title,
                        receive_notif: event.receive_notif === true ? "✅" : "❌",
                        updateState: Refresh.bind(this),
                        remind_before: event.remind_before,
                        setOpen: setOpen,
                        setEvent: setEvent_id
                    }
                })
            );
        }).catch(error => {
            console.log(error);
        });
    };


    useEffect(() => {
        Refresh();
    },[]);

    return (
        <div className="allRemindersTable">
            <h1 className="pageHeader">Reminders</h1>
            <EventsTable rows={events} />

            {
                open ?
                    <CreateEventForm
                        person_id={null}
                        mode={mode}
                        refresh={Refresh}
                        token={token}
                        setOpen={setOpen}
                        event_id={event_id}
                    /> : null
            }

        </div>
    );
}

export default Reminders;