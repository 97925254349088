import React, {useEffect, useState} from "react";
import {get} from "../utils/useApi";
import {useAuth} from "../components/AuthProvider";
import EventsTable from "../components/EventsTable";
import {useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import {decodeCreditional} from "../utils/googleApi";
import dayjs from "dayjs";



function EventView() {
    const [event, setEvent] = useState();
    const [person, setPerson] = useState();

    const {token} = useAuth();

    const location = useLocation();
    const event_id = location.pathname.split("/")[2];

    let decoded = decodeCreditional(token);
    let userid = decoded.sub;

    const Refresh = () => {
            get(token, `/events/${event_id}`, null).then(response => {
                setEvent(
                    response
                );
                get(token, '/persons/user/' + userid, null).then(response2 => {
                    setPerson(
                        response2.find(person_ => person_.person_id === response.person_id)
                    );
                }).catch(
                    error => {
                        console.log(error);
                        toast.error("Error fetching persons")
                    }
                );
            }).catch(error => {
                console.log(error);
                toast.error("Error fetching events")
            });
    };

    useEffect(() => {
        Refresh();
    }, []);


    return (
        <div className="allRemindersTable">
        {/*    make beautiful view of the data*/}

            <h1 className={"pageHeader"}>Event</h1>
            {event ? [
                <h2 key={"title"}>{event.title}</h2>,
                <p key={
                    "description"
                }>
                    Description 📄: {event.description}
                </p>,
                <p key={"eventtype"}>
                    Event Type 🌀: {event.event_type}
                </p>,
                <p key={"receive"}>
                    Receive Notification 🔔: {event.receive_notif === true ? "✅" : "❌"}
                </p>,
                <p key={"rem_bef"}>
                    Remind Before ⌚: {event.remind_before}
                </p>,
                <p key={"date"}>
                    Date 📅: {dayjs(
                    event.year + "-" + (event.month + 1) + "-" + event.day
                    ).format("MMMM D, YYYY")
                }
                </p>

                ] :
            <p>loading.</p>}
            _______________________________________________________
            {person ? [
                <p key={"person"}>Person: {person.name}</p>,
                <p key={"rel"}>Relation: {person.relationship_type}</p>
                ] :
                <p>loading person</p>}



        </div>
    );
}

export default EventView;