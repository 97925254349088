import React, {useEffect} from "react";
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';
import {useAuth} from "./AuthProvider";
import {post, get, put} from "../utils/useApi";
import {toast} from "react-toastify";
import {Checkbox, MenuItem, Select} from "@mui/material";
import dayjs from "dayjs";

/**
 *
 * @param refresh function to refresh the page
 * @param update if true, update this event, else create a new event
 * @param person_id
 * @returns {Element}
 * @constructor
 */
function CreateEventForm({refresh, person_id_=null, event_id = null, mode, setOpen}) {

    const {token, userid} = useAuth();

    const [remindBefore, setRemindBefore] = React.useState("day");
    const [date, setDate] = React.useState(dayjs().format("YYYY-MM-DD"));
    const [title, setTitle] = React.useState("");
    const [event_type, setEvent_type] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [receive_notif, setReceive_notif] = React.useState(true);
    const [person_id, setPerson_id] = React.useState(person_id_);

    useEffect(() => {
        console.log("personsss", person_id);
        if(event_id){
            get(token, "/events/" + event_id, null).then(response => {
                console.log(response);
                setTitle(response.title);
                setEvent_type(response.event_type);
                setDescription(response.description);
                setReceive_notif(response.receive_notif);
                setRemindBefore(response.remind_before);
                setPerson_id(response.person_id);
                setDate(dayjs(response.year + "-" + (response.month +1) + "-" + response.day));
            }).catch(error => {
                console.log(error);
                toast.error("Error fetching event")
            });

            setOpen(true);
            console.log(typeof receive_notif)
        }

        if(mode==="birthday"){
            setEvent_type("birthday");
        }
    }, []);

    return (
        <>

            <Modal open={true} onClose={() => setOpen(false)}>
                <ModalDialog>
                    <DialogTitle>
                        {event_id ? "Update " : "Create "}
                        {mode === "birthday" ? "Birthday 🎂" : "Event 📅"}
                    </DialogTitle>
                    <DialogContent>
                        {event_id ? "Update the event details" : "Create a new event"}
                    </DialogContent>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            setOpen(false);

                            let data = {
                                user_id: userid,
                                person_id: person_id,
                                title: title,
                                event_type: event_type,
                                description: description,
                                receive_notif: receive_notif,
                                remind_before: remindBefore,
                                day: dayjs(date).date(),
                                month: dayjs(date).month(),
                                year: dayjs(date).year(),
                            }


                            console.log(data);
                            if(!event_id) {
                                post(token, "/events", data).then(response => {
                                    toast.success("Event created.")
                                    refresh();
                                }).catch(error => {
                                    console.log(error);
                                    toast.error("Error creating event.")
                                });
                            }
                            else{
                                put(token, "/events/", {
                                    ...data,
                                    event_id: event_id
                                }).then(response => {
                                    toast.success("Event updated.")
                                    refresh();
                                });
                            }
                        }}>

                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Title</FormLabel>
                                <Input autoFocus required placeholder={"Dad's Birthday"}
                                onChange={
                                    (event) => setTitle(event.target.value)
                                }
                                value={title}
                                />
                            </FormControl>


                            { mode !== "birthday" &&
                                <FormControl>
                                    <FormLabel>Event Type</FormLabel>
                                    <Input required placeholder={"Anniversary"}
                                           onChange={(event) => setEvent_type(event.target.value)}
                                             value={event_type}
                                    />
                                </FormControl>
                            }

                            <FormControl>
                                <FormLabel>Description</FormLabel>
                                <Input placeholder={"Dont forget to buy a gift"}
                                onChange={
                                    (event) => setDescription(event.target.value)
                                }
                                value={description}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Receive Notification</FormLabel>
                                <Checkbox
                                    onChange={
                                    (event) => {
                                        console.log("checkbox:"+event.target.checked);
                                        setReceive_notif(event.target.checked)
                                    }

                                }
                                    checked={receive_notif}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Remind Before</FormLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={remindBefore}
                                    label="Remind Before"
                                    onChange={(event) => setRemindBefore(event.target.value)}
                                >
                                    <MenuItem value={"day"}>1 Day</MenuItem>
                                    <MenuItem value={"3day"}>3 Days</MenuItem>
                                    <MenuItem value={"week"}>1 Week</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Date</FormLabel>
                                <Input
                                    type="date"
                                    required
                                    value={dayjs(date).format("YYYY-MM-DD")}
                                    onChange={(event) => {
                                        setDate(dayjs(event.target.value, "YYYY-MM-DD"))
                                        console.log(
                                            dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD")
                                        );
                                    }}
                                    slotProps={
                                        {
                                            input: {
                                                format: "DD/MM/YYYY"
                                            }
                                        }
                                    }


                                />
                            </FormControl>

                            <Button type="submit">Submit</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        </>
    );
}


export default CreateEventForm;