import {GoogleLogin} from '@react-oauth/google';
import React from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {useNavigate} from 'react-router-dom';
import {useAuth} from "./AuthProvider";
import {get, post} from '../utils/useApi';
import {decodeCreditional} from '../utils/googleApi';

const LoginButton =  () => {
    const navigate = useNavigate();
    const {setToken} = useAuth();

    return (
            <GoogleLogin
                clientId="741991010133-ied9j2r03vjh3ust8fshihfna2kqtc3o.apps.googleusercontent.com"
                onSuccess={(response) => {

                    let token = response.credential;
                    setToken(token);

                    try {
                        let userurl = "/users/" + decodeCreditional(token).sub;

                        get(token, userurl, null).then((data) => {

                            if (data.error === "User does not exist.") {
                                //add user
                                console.log("adding user");
                                let user = {
                                    "user_id": decodeCreditional(token).sub,
                                    "name": decodeCreditional(token).name,
                                    "email": decodeCreditional(token).email,
                                }
                                post(token, "/users", user);
                                navigate("/reminders");
                                toast.success("Login Successful");
                            } else {
                                console.log("user exists");
                                navigate("/reminders");
                                toast.success("Login Successful");
                            }
                        }).catch((error) => {
                            toast.error(error.message);
                        });
                    } catch (error) {
                        toast.error(error.message);
                    }
                }}
                onFailure={(response) => {
                    console.log(response);
                    toast.error("Login Failed");
                }}

                theme={"outline"}
                shape={"rectangular"}
            >

            </GoogleLogin>

    )
}






export default LoginButton;
