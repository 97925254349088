import React, {useEffect} from "react";
import {get, put} from "../utils/useApi";
import {toast} from "react-toastify";
import Stack from "@mui/joy/Stack";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Button from "@mui/joy/Button";
import {useAuth} from "../components/AuthProvider";
import {decodeCreditional} from "../utils/googleApi";
import {Checkbox} from "@mui/material";

function Settings(fn) {
    const {token} = useAuth();
    let userid = decodeCreditional(token).sub;

    const [user, setUser] = React.useState({});
    const [receiveNotif, setReceiveNotif] = React.useState(false);

    useEffect(() => {
        //get the user's settings
        get(token, "/users/" + userid,null).then(r => {
            setUser(r);
            setReceiveNotif(r.receive_notif);
            console.log(r);
            toast.success("Fetched settings")
        }).catch(e => {
            console.log(e);
            toast.error("Failed to get user's settings.");
        });
    },[]);

    return (
        <div>
            <h1 className={"pageHeader"}>Settings</h1>

            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    put(token, "/users/" + userid, {
                        user_id: userid,
                        receive_notif: receiveNotif,
                        email: user.email,
                        name: user.name
                    }).then(r => {
                        toast.success("Settings updated.");
                    }).catch(e => {
                        console.log(e);
                        toast.error("Failed to update settings.");
                    });

                }}>
                <Stack spacing={2}>
                    <FormControl>
                        <FormLabel style={{
                            "alignSelf": "center",
                            "fontSize": "20px",
                        }}>Receive Notifications</FormLabel>
                        <Checkbox
                            checked={receiveNotif}
                            onChange={
                                (event) => {
                                    setReceiveNotif(event.target.checked);
                                }
                            }/>
                    </FormControl>

                    <Button type="submit"
                            style={{
                                "width": "100px",
                                "marginTop": "20px",
                                "alignSelf": "center",
                            }}>Submit</Button>
                </Stack>
            </form>
        </div>
    );
}

export default Settings;
